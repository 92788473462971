import { Link } from "react-router-dom";

import CenterMode from "../components/CenterMode";
import "./css/precios.css";
import "./css/paquetes.css";
import Pricing from "../components/Pricing";
import Footer from "../components/Footer";
import { useEffect, useState } from "react";
import {
  faBars,
  faX,
  faEnvelope,
  faMicrochip,
  faComment,
  faMoon,
  faSatelliteDish,
  fa1,
  faChartSimple,
  faLaptopCode,
  faHelmetSafety,
  faArrowDown
} from "@fortawesome/free-solid-svg-icons";
import {
  faInstagram,
  faLinkedin,
  faTiktok,
  faWhatsapp,
  faTelegram,
} from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import HeaderF from "../components/HeaderF";
import HeaderFM from "../components/HeaderFM";
import ReactGA from "react-ga4";
import AOS from "aos";

const Precios = (props) => {
  const handleReach = (website) => {

    let emailAddress = "innovatech@gmail.com";
    let subject = "Interest in your services.";
    let body = "Hi, I'm interested in your websites. Could you please provide more information about the services you offer?";

    // Construct the mailto URL
    let mailtoUrl = "mailto:" + encodeURIComponent(emailAddress) +
                    "?subject=" + encodeURIComponent(subject) +
                    "&body=" + encodeURIComponent(body);

    // Set the location.href to the mailto URL
    window.location.href = mailtoUrl;
   }



  const landingP = "$250";
  const landingP2 = "$70";
  const landingSP = "$25";
  const basicaP = "$305";
  const basicaP2 = "$105";
  const basicaSP = "$50";
  const cartaP = "$95";
  const cartaP2 = "$20";
  const cartaSP = "$50";
  const menuP = "$690";
  const menuP2 = "$265";
  const menuSP = "$50";
  const eccomerceP = "$1590";
  const eccomerceP2 = "$650";
  const eccomerceSP = "$90";

  const [img1, setimg1] = useState(true);
  const [img2, setimg2] = useState(false);
  const [img3, setimg3] = useState(false);
  const [img4, setimg4] = useState(false);
  const [img5, setimg5] = useState(false);
  const [img6, setimg6] = useState(false);
  const [img7, setimg7] = useState(false);



  const [header, setHeader] = useState(false);
  const handleHeader = () => {
    setHeader(!header);
  };

  const [index, setIndex] = useState(0);
 

const handleSelect = (selectedIndex) => {
  setIndex(selectedIndex);
};
const scrollToTop = () => {
  window.scrollTo({
    top: 0,
    behavior: "smooth"
  });
};
  const handleStart = () => {
    scrollToTop()
  };
  const handleStartM = () => {
    const element = document.getElementById("porQueM");
  
    if (element) {
      const offset = element.getBoundingClientRect().top + window.pageYOffset;
      window.scrollTo({
        top: offset,
        behavior: "smooth"
      });
    }
  };
  
  const handleOnline = () => {
    setimg1(false);
    setimg2(true);
  };
  const handleOnlineF = () => {
    setimg1(true);
    setimg2(false);
  };

  const handleBuss = () => {
    setimg1(false);
    setimg3(true);
  };
  const handleBussF = () => {
    setimg1(true);
    setimg3(false);
  };
  const handleSer = () => {
    setimg1(false);
    setimg4(true);
  };
  const handleSerF = () => {
    setimg1(true);
    setimg4(false);
  };
  const handleCom = () => {
    setimg1(false);
    setimg5(true);
  };
  const handleComF = () => {
    setimg1(true);
    setimg5(false);
  };
  const handleEve = () => {
    setimg1(false);
    setimg6(true);
  };
  const handleEveF = () => {
    setimg1(true);
    setimg6(false);
  };
  const handleMem = () => {
    setimg1(false);
    setimg7(true);
  };
  const handleMemF = () => {
    setimg1(true);
    setimg7(false);
  };
  useEffect(() => {
    AOS.init();

    handleStart()
   
  }, []);

  return (
    <div>
      {/* English */}
      {props.eng && (
        <div>
          {/* DESK ENG */}
          <div className="desk porQue" id="porQue">
           
            <div className="precios">
              <HeaderF eng={true} h2={true}></HeaderF>

              <div className="landingPrecios">
                <h2 data-aos="fade-rigth" className="h2LandingPrecios">
                  Designed to Excel.
                </h2>
                <div data-aos="fade-bottom" className="linkLandingPDiv">
                <a onClick={handleReach}  className="linkLandingPrecios">Contact Us</a>
                  <h6 className="h6LandingPrecios">
                    Get a free estimation.
                    <br />
                    No payments required.
                  </h6>
                </div>
              </div>

              <div className="upperPrecios">
                <div className="leftUpper">
                  <h2 className="expandir-txt-arriba h2Precios">
                    A plan for every need<span className="color">.</span>
                  </h2>
                </div>
                <div className="rightUpper">
                  <h6 className="expandir-txt-arriba h6Precios">
                    You can also subscribe for exclusive web membership
                    benefits: reduced prices, free maintenance, updates, and
                    more! No extra costs for content, domain, or hosting.
                  </h6>
                  <Link className="learnMore" to="/member">
                    Learn More
                  </Link>
                </div>
              </div>

              <div className="preciosShowcase">
                <div className="selector">
                  <div className="txtSelector">
                    <Link
                      to="portfolio"
                      className={
                        img1 ? "optionSelector selected" : "optionSelector"
                      }
                    >
                      Portfolio{" "}
                    </Link>
                    <Link
                      to="store"
                      className="optionSelector"
                      onMouseEnter={handleOnline}
                      onMouseLeave={handleOnlineF}
                    >
                      Online Store
                    </Link>
                    <Link
                      to="business"
                      className="optionSelector"
                      onMouseEnter={handleBuss}
                      onMouseLeave={handleBussF}
                    >
                      Business{" "}
                    </Link>
                    <Link to='service' className="optionSelector" onMouseEnter={handleSer}
                      onMouseLeave={handleSerF}>Services</Link>
                    <Link to='companies' className="optionSelector" onMouseEnter={handleCom}
                      onMouseLeave={handleComF}>Companies</Link>
                    <Link to='events' onMouseEnter={handleEve}
                      onMouseLeave={handleEveF} className="optionSelector">Events</Link>
                    <Link to='subscriptions' className="optionSelector" onMouseEnter={handleMem}
                      onMouseLeave={handleMemF}>Subscriptions</Link>
                  </div>
                </div>

                <div className="imgSelected">
                  {img1 && (
                    <img
                      data-aos="fade-right"
                      src={require("../img/santinoBlasc.png")}
                      alt=""
                    />
                  )}
                  {img2 && (
                    <img
                      data-aos="fade-right"
                      src={require("../img/onlineStore.png")}
                      alt=""
                    />
                  )}
                  {img3 && (
                    <img
                      data-aos="fade-right"
                      src={require("../img/negocio.png")}
                      alt=""
                    />
                  )}
                  {img4 && (
                    <img
                      data-aos="fade-right"
                      src={require("../img/delpra.png")}
                      alt=""
                    />
                  )}
                   {img5 && (
                    <img
                      data-aos="fade-right"
                      src={require("../img/company.png")}
                      alt=""
                    />
                  )}
                  {img6 && (
                    <img
                      data-aos="fade-right"
                      src={require("../img/event.png")}
                      alt=""
                    />
                  )}
                  {img7 && (
                    <img
                      data-aos="fade-right"
                      src={require("../img/membership.png")}
                      alt=""
                    />
                  )}
                </div>
              </div>
            </div>

            <div className="landing2">
        <div data-aos="fade-up" className="txt2">
          <h4 className="brand2">
          <FontAwesomeIcon icon={faMicrochip}></FontAwesomeIcon> innova<span className="devx2">.devx</span>          </h4>
          <h3 className="landingDesc2">Innovate with us now.</h3>
          <br />
          <p className="pCaracF">Automate and simplify your business with a unique, effective, and competitive solution.</p>
          <br />
          <br />
          <br />
          <br />
          <Link onClick={handleReach} className="landingBtn2">Start Today</Link>
        </div>
        <div data-aos="fade-up" className="img2">
          <img src={require("../img/footer.png")} alt="" />
          <div className="redes">
            <h6>Reach us here:</h6>
            <div>
              <a className="aIno" href="mailto:innovadevx@gmail.com
"><FontAwesomeIcon icon={faEnvelope}></FontAwesomeIcon></a>
             
              <a className="aIno" href="https://www.instagram.com/innovadevx/"><FontAwesomeIcon icon={faInstagram}></FontAwesomeIcon></a>
              <a className="aIno" href="sms:+5716269126?body=Hi%im%20interested%20in%20your%20services."><FontAwesomeIcon icon={faComment}></FontAwesomeIcon></a>
            </div>
          </div>
        </div>
      </div>
          </div>

          {/* MOBILE ENG */}
          <div className="mobile porQue" id="porQueM">
            <HeaderFM black={true}></HeaderFM>
            {/* <a href="emailto:innovadevx@gmail.com" className="linkLandingPrecios">Contact Us</a> */}
            <div className="preciosLandingM">
              <div className="divTxtPLan">

              {/* <h6 className="h6LandingPrecios">
                Our websites are
              </h6>
              <h2 data-aos="fade-rigth" className="h2LandingPrecios">
                Designed to excel.
              </h2> */}

              
              </div>

             

            </div>

{/* <div className="antesDeMidMobile">
  <h4>We offer endless possibilities for your website, you imagine it we make it real but in case you dont entirely know what you want here are some of our types of websites:
  </h4>
  <h6><FontAwesomeIcon icon={faArrowDown}></FontAwesomeIcon></h6>
</div> */}
          <div className="midPrecios">
          
<CenterMode></CenterMode>
          </div>


            <div className="landing2">
              <div className="img2" data-aos="fade-up" data-aos-duration="1000">
                <img src={require("../img/footer.png")} alt="" />
              </div>
              <div className="txt2" data-aos="fade-up" data-aos-duration="1000">
                <h3 className="landingDesc2">Innovate with us now.</h3>
                <br />
                <p className="pCarac">
                Automate and simplify your business with a unique, effective, and competitive solution.


                </p>

                <button onClick={handleReach} className="landingBtn">Start Today</button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Precios;
