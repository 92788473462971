import "./css/home.css";
import { useState, useEffect } from "react";
import Spinner from "../components/Spinner";

import {
  faInstagram,
  faLinkedin,
  faTiktok,
  faWhatsapp,
  faGithub,
} from "@fortawesome/free-brands-svg-icons";
import {
  faAngleLeft,
  faAngleRight,
  faBars,
  faBriefcase,
  faFileLines,
  faX,
  faEnvelope,
  faMicrochip,
  faComment
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css";


const Home = (props) => {
  let esp = props.esp;
  let eng = props.eng;
  var idioma = navigator.language || navigator.languages[0];

  const [spanish, setSpanish] = useState(esp);
  const [english, setEnglish] = useState(eng);
 
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  };
    const handleStart = () => {
      scrollToTop()
    };
  


  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(false);
    setTimeout(() => {
      setLoading(false);
    }, 100);

    

    if (true) {
      setEnglish(true);
      setSpanish(false);
    }
    AOS.init();
   handleStart();
  }, []);

  return (
    <div className="homeDiv">
      {props.init && (
        <div>
          {loading && (
            <div>
              <Spinner></Spinner>
            </div>
          )}
          {!loading && (
         <div>
         <div className="desk">
         
      <div className="landing">
       
        <div className="txt" data-aos="fade-up" data-aos-duration="1000">
          <h4 className="brand">
            <FontAwesomeIcon icon={faMicrochip}></FontAwesomeIcon> innova<span className="devx2">.devx</span>
          </h4>
          <h1>Innova<span className="devx text-flicker-in-glow">Devx</span></h1>
          <h3 className="landingDesc">Innovating Your Online Presence.</h3>
          <br />
          <br />
          <Link to='/services' className="landingBtn">Get an Estimate for Free</Link>
        </div>
        <div data-aos="fade-right" data-aos-duration="1000" className="img">
          <img src={require("../img/landingIMg.png")} alt="" />
        </div>
      </div>
  
      <div data-aos="fade-up" className="info1">
        <div className="cuadradoInfo1">
          <h2 className="h2Info">
            Software doesn't have to feel like a different language.
          </h2>
          <div className="derInfo1">
            <img src={require("../img/info2.png")} alt="" />
            <div>
              <p className="pInfo1">We founded Innova to provide you with solutions that make work easier, so you can enjoy life more.</p>
              <br />
              <Link to='/about' className="btnInfo1">Learn More</Link>
            </div>
          </div>
        </div>
      </div>
  
      <div className="info2">
        <div className="caracs">
          <div data-aos="zoom-in" className="carac">
            <img className="imgCarac" src={require("../img/el1Carac.png")} alt="" />
            <h4 className="h4Carac">The Best Websites</h4>
            <p className="pCarac">Our websites feature outstanding designs and flawless functionality, competing among the best.</p>
          </div>
          <div data-aos="zoom-in" className="carac">
            <img className="imgCarac" src={require("../img/el2Carac.png")} alt="" />
            <h4 className="h4Carac">Save Lots of Money</h4>
            <p className="pCarac">We've got quality and price teaming up for the ultimate deal. Look around, you won't find a better offer!</p>
          </div>
          <div data-aos="zoom-in" className="carac">
            <img className="imgCarac" src={require("../img/el3Carac.png")} alt="" />
            <h4 className="h4Carac">Real Websites</h4>
            <p className="pCarac">Say goodbye to website builders that fail to attract clients. Our expertly crafted websites guarantee your success.</p>
          </div>
          
        </div>
        <Link to='/services' className="btnInfo2">EXPLORE</Link>
      </div>
  
      <div className="info3">
        <div data-aos="fade-up" className="izqInfo3">
          <h4>An Innova website provides tailored design and invaluable customer insights.</h4>
          <p>An Innova website is <b>meticulously crafted</b> to suit your <b>unique business and brand</b>. Additionally, it empowers you to gather <b>essential insights</b> about your prospective customers or clients.</p>
          <br />
          <br />
          <Link to='/about' className="btnInfo3">Learn More</Link>
        </div>
        <div data-aos="fade-up" className="derInfo3">
          <img src={require("../img/BusinessPlan.png")} alt="" />
        </div>
      </div>
      
      
      <div className="landing2">
        <div data-aos="fade-up" className="txt2">
          <h4 className="brand2">
          <FontAwesomeIcon icon={faMicrochip}></FontAwesomeIcon> innova<span className="devx2">.devx</span>          </h4>
          <h3 className="landingDesc2">Innovate with us now.</h3>
          <br />
          <p className="pCaracF">Automate and simplify your business with a unique, effective, and competitive solution.</p>
          <br />
          <br />
          <br />
          <br />
          <Link to='/services' className="landingBtn2">Start Today</Link>
        </div>
        <div data-aos="fade-up" className="img2">
          <img src={require("../img/footer.png")} alt="" />
          <div className="redes">
            <h6>Reach us here:</h6>
            <div>
              <a className="aIno" href="mailto:innovadevx@gmail.com
"><FontAwesomeIcon icon={faEnvelope}></FontAwesomeIcon></a>
             
              <a className="aIno" href="https://www.instagram.com/innovadevx/"><FontAwesomeIcon icon={faInstagram}></FontAwesomeIcon></a>
              <a className="aIno" href="sms:+5716269126?body=Hi%im%20interested%20in%20your%20services."><FontAwesomeIcon icon={faComment}></FontAwesomeIcon></a>
            </div>
          </div>
        </div>
      </div>
    </div>
     {/* Mobile */}
     <div className="mobile">
      <div className="landingM">

        <div className="txt1" >
        <h4 className="brand">
            <FontAwesomeIcon icon={faMicrochip}></FontAwesomeIcon> innova<span className="devx2">.devx</span>
          </h4>
        </div>

        <div className="imgM" >
          <img src={require("../img/landingIMg.png")} alt="" />
        </div>

        <div className="txt" >
          {/* <h1>Innovatech</h1> */}
          <h1>Innova<span className="devxM text-flicker-in-glow">Devx</span></h1>
          <h3 className="landingDesc">Innovation that flows.</h3>
          <br />
          
          <Link to='/services' className="landingBtn">Get an Estimate free</Link>
          <br />
          <br />
        </div>
      </div>

      <div className="info1">
        <div className="cuadradoInfo1" data-aos="fade-up" data-aos-duration="1000">
          <h2 className="h2Info">
            Software doesn't have to feel like a different language.
          </h2>
          <div className="derInfo1">
            <img src={require("../img/info2.png")} alt="" />
          </div>
          <div>
            <p className="pInfo1">We founded Innova to provide you with solutions that make work easier, so you can enjoy life more.</p>
           <Link to='/about'> <button className="btnInfo1">Learn More</button></Link>
          </div>
        </div>
      </div>

      <div className="info2">
        <div className="caracs" data-aos="fade-up" data-aos-duration="1000">
          <br />
          <div className="carac">
            <img className="imgCarac" src={require("../img/el1Carac.png")} alt="" />
            <h4 className="h4Carac">The Best Websites</h4>
            <p className="pCarac">Our websites feature outstanding designs and flawless functionality, competing among the best.</p>
          </div>
          <div className="carac" data-aos="fade-up" data-aos-duration="1000">
            <img className="imgCarac" src={require("../img/el2Carac.png")} alt="" />
            <h4 className="h4Carac">Save Lots of Money</h4>
            <p className="pCarac">We've got quality and price teaming up for the ultimate deal. Look around, you won't find a better offer!</p>
          </div>
          <div className="carac" data-aos="fade-up" data-aos-duration="1000">
            <img className="imgCarac" src={require("../img/el3Carac.png")} alt="" />
            <h4 className="h4Carac">Real Websites</h4>
            <p className="pCarac">Say goodbye to website builders that fail to attract clients. Our expertly crafted websites guarantee your success.</p>
          </div>
          <br />
        </div>
      </div>

      <div className="info3">
        <div className="izqInfo3" data-aos="fade-up" data-aos-duration="1000">
          <img src={require("../img/BusinessPlan.png")} alt="" />
          <h4>
          An Innova website provides tailored design and invaluable customer insights.
          </h4>
          <p>An Innova website is meticulously crafted to suit your unique business and brand. Additionally, it empowers you to gather essential insights about your prospective customers or clients.</p>
          <Link to='/about' className="btnInfo3">Learn More</Link>

        </div>
      </div>

      <div className="landing2">
        <div className="img2" data-aos="fade-up" data-aos-duration="1000">
          <img src={require("../img/footer.png")} alt="" />
        </div>
        <div className="txt2" data-aos="fade-up" data-aos-duration="1000">
          <h3 className="landingDesc2">Innovate with us now.</h3>
          <br />
          <p className="pCarac">Automate and simplify your business with a unique, effective, and competitive solution.</p>
          <br />
          <br />
          <Link to='/services' className="landingBtn">Start Today</Link>
        </div>
      </div>
    </div>
       </div>
      )}
        </div>
      )}

      {!props.init && (
        <div>
          <div>
            {/* DESK */}
            <div className="desk">
      <div className="landing">
        <div className="txt" data-aos="fade-up" data-aos-duration="1000">
          <h4 className="brand">
            <FontAwesomeIcon icon={faMicrochip}></FontAwesomeIcon> innovatech.dev
          </h4>
          <h1>Innovatech</h1>
          <h3 className="landingDesc">Innovation that flows</h3>
          <Link to='/services' className="landingBtn">Get an Estimate for Free</Link>
        </div>
        <div data-aos="fade-right" data-aos-duration="1000" className="img">
          <img src={require("../img/landingIMg.png")} alt="" />
        </div>
      </div>
  
      <div data-aos="fade-up" className="info1">
        <div className="cuadradoInfo1">
          <h2 className="h2Info">
            Software doesn't have to feel like a different language.
          </h2>
          <div className="derInfo1">
            <img src={require("../img/info2.png")} alt="" />
            <div>
              <p className="pInfo1">We created Innovatech to provide your business with solutions that make work easier, so you can enjoy life more.</p>
              <br />
              <Link to='/services' className="btnInfo1">Learn More</Link>
            </div>
          </div>
        </div>
      </div>
  
      <div className="info2">
        <div className="caracs">
          <div data-aos="zoom-in" className="carac">
            <img className="imgCarac" src={require("../img/el1Carac.png")} alt="" />
            <h4 className="h4Carac">Best Websites</h4>
            <p className="pCarac">Our websites have stellar designs and flawless functionality, allowing them to compete among the best.</p>
          </div>
          <div data-aos="zoom-in" className="carac">
            <img className="imgCarac" src={require("../img/el2Carac.png")} alt="" />
            <h4 className="h4Carac">Save Lots of Money</h4>
            <p className="pCarac">We've got quality and price teaming up for the ultimate deal. Look around, you won't find a better offer!</p>
          </div>
          <div data-aos="zoom-in" className="carac">
            <img className="imgCarac" src={require("../img/el3Carac.png")} alt="" />
            <h4 className="h4Carac">Privacy Guaranteed</h4>
            <p className="pCarac">At Innovatech, we fully commit to not collecting any data from your website, ensuring 100% absolute privacy.</p>
          </div>
        </div>
        <button className="btnInfo2">EXPLORE</button>
      </div>
  
      <div className="info3">
        <div data-aos="fade-up" className="izqInfo3">
          <h4>
            Simplifying my business processes with Innovatech freed up time for me to focus on expanding my company.
          </h4>
          <p>Innovatech has been a true revolution for my business. Before using this solution, I spent hours and hours dealing with manual and repetitive tasks that consumed my time and energy. But since I implemented Schematiq, my business life has completely changed</p>
          <h5>Benicio Rosales, Online Business Owner.</h5>
        </div>
        <div data-aos="fade-up" className="derInfo3">
          <img src={require("../img/man.jpg")} alt="" />
        </div>
      </div>
      
     
      <div className="landing2">
        <div data-aos="fade-up" className="txt2">
          <h4 className="brand2">
            <FontAwesomeIcon icon={faMicrochip}></FontAwesomeIcon> innovatech
          </h4>
          <h3 className="landingDesc2">Innovate with us now.</h3>
          <br />
          <p className="pCarac">Automate and simplify your business with a secure, effective, and private solution.</p>
          <br />
          <br />
          <button className="landingBtn2">Start Today</button>
        </div>
        <div data-aos="fade-up" className="img2">
          <img src={require("../img/footer.png")} alt="" />
          <div className="redes">
            <h6>Reach us here:</h6>
            <div>
              <a className="aIno" href="mailto:jmbroggidev@gmail.com"><FontAwesomeIcon icon={faEnvelope}></FontAwesomeIcon></a>
              <a className="aIno" href="https://wa.me/5716269126?text=%C2%A1Hi!"><FontAwesomeIcon icon={faWhatsapp}></FontAwesomeIcon></a>
              <a className="aIno" href="https://www.instagram.com/jmbroggi.dev/"><FontAwesomeIcon icon={faInstagram}></FontAwesomeIcon></a>
            </div>
          </div>
        </div>
      </div>
  
    </div>
    {/* Mobile */}
    <div className="mobile">
      <div className="landingM">

        <div className="txt1" data-aos="fade-up" data-aos-duration="1000">
          <h4 className="brand">
            <FontAwesomeIcon icon={faMicrochip}></FontAwesomeIcon> innovatech
          </h4>
        </div>

        <div className="imgM" data-aos="fade-up" data-aos-duration="1000">
          <img src={require("../img/landingIMg.png")} alt="" />
        </div>

        <div className="txt" data-aos="fade-up" data-aos-duration="1000">
          <h1>Innovatech</h1>
          <h3 className="landingDesc">Innovation that flows.</h3>
          <button className="landingBtn">Try it for Free</button>
        </div>
      </div>

      <div className="info1">
        <div className="cuadradoInfo1" data-aos="fade-up" data-aos-duration="1000">
          <h2 className="h2Info">
            Software doesn't have to feel like a different language.
          </h2>
          <div className="derInfo1">
            <img src={require("../img/info2.png")} alt="" />
          </div>
          <div>
            <p className="pInfo1">We created Innovatech to provide your business with solutions that make work easier, so you can enjoy life more</p>
            <button className="btnInfo1"><Link to='/services'>Learn More</Link></button>
          </div>
        </div>
      </div>

      <div className="info2">
        <div className="caracs" data-aos="fade-up" data-aos-duration="1000">
          <div className="carac">
            <img className="imgCarac" src={require("../img/el1Carac.png")} alt="" />
            <h4 className="h4Carac">Artificial Intelligence</h4>
            <p className="pCarac">Connect Innovatech's AI to your website and never write boring articles again.</p>
          </div>
          <div className="carac" data-aos="fade-up" data-aos-duration="1000">
            <img className="imgCarac" src={require("../img/el2Carac.png")} alt="" />
            <h4 className="h4Carac">Financial Predictions</h4>
            <p className="pCarac">Innovatech uses AI to provide you with financial predictions with a 90% accuracy level, giving you solid and reliable information for decision-making.</p>
          </div>
          <div className="carac" data-aos="fade-up" data-aos-duration="1000">
            <img className="imgCarac" src={require("../img/el3Carac.png")} alt="" />
            <h4 className="h4Carac">Privacy Guaranteed</h4>
            <p className="pCarac">At Innovatech, we fully commit to not collecting any data from your website, ensuring 100% absolute privacy.</p>
          </div>
        </div>
      </div>

      <div className="info3">
        <div className="izqInfo3" data-aos="fade-up" data-aos-duration="1000">
          <img src={require("../img/man.jpg")} alt="" />
          <h4>
            -Simplifying my business processes with Innovatech freed up time for me to focus on expanding my company.
          </h4>
          <p>Innovatech has been a true revolution for my business. Before using this solution, I spent hours and hours dealing with manual and repetitive tasks that consumed my time and energy. But since I implemented Schematiq, my business life has completely changed</p>
          <h5>Benicio Rosales, Online Business Owner</h5>
        </div>
      </div>

      <div className="landing2">
        <div className="img2" data-aos="fade-up" data-aos-duration="1000">
          <img src={require("../img/footer.png")} alt="" />
        </div>
        <div className="txt2" data-aos="fade-up" data-aos-duration="1000">
          <h3 className="landingDesc2">Innovate with us now.</h3>
          <br />
          <p className="pCaracF">Automate and simplify your business with a secure, effective, and private solution.</p>
          <br />
          <br />
          <button className="landingBtn">Start Today</button>
        </div>
      </div>
    </div>

           
          </div>
        </div>
      )}
    </div>
  );
};

export default Home;
