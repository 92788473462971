import React, { useRef, useState } from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
import { Link } from 'react-router-dom';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';


// import required modules
import { Pagination } from 'swiper/modules';

export default function CenterMode() {
  return (
    <>
      <Swiper
        spaceBetween={30}
        pagination={{
          clickable: true,
        }}
        modules={[Pagination]}
        className="mySwiper"
      >
        <h3>Websites for every need</h3>

        <SwiperSlide>
<Link to='service' className='serviceLinkMobile'>

<div className='slider slider1'>
          

          </div></Link>
        </SwiperSlide>

        <SwiperSlide><Link to='business'>
        <div className='slider slider2'>
          

          </div>
        
        </Link></SwiperSlide>

        <SwiperSlide><Link to='store'>
        <div className='slider slider3'>
          

          </div>
        </Link></SwiperSlide>

          <SwiperSlide><Link to='subscriptions'>
          <div className='slider slider4'>
          

          </div>
          </Link></SwiperSlide>

          <SwiperSlide><Link to='companies'>
          
          <div className='slider slider5'>
          

          </div>
          </Link></SwiperSlide>

          <SwiperSlide><Link to='events'>
          <div className='slider slider6'>
          

          </div>
          </Link></SwiperSlide>
          
      </Swiper>
    </>
  );
}
