import "./css/precio.css";
import HeaderF from "../components/HeaderF";
import HeaderFM from "../components/HeaderFM";
import FooterM from "../components/FooterM";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleCheck, faCircleXmark, faEnvelope, faGlobe,faComment, faMicrochip, faPhone,  } from "@fortawesome/free-solid-svg-icons";
import { faWhatsapp, faInstagram } from "@fortawesome/free-brands-svg-icons";
import { Link, useNavigate } from "react-router-dom";
import AOS from 'aos';
import 'aos/dist/aos.css';
import Footer from "../components/Footer";
import { useEffect } from "react";
import ReactGA from 'react-ga4';

const Precio = (props) => {

    let planName = props.plan;

const scrollToTop = () => {
  window.scrollTo({
    top: 0,
    behavior: "smooth"
  });
};
  const handleStart = () => {
    scrollToTop()
  }; 
      const navigate = useNavigate();

  const handleContacto = () => {
   
    navigate('examples');
  };
  const handleContactoAB = () => {
   
    navigate('/services');
  };

      const handleWp = () => {
        let href = `https://wa.me/15716269126?text=Hi!%20i'd%like%you%to%20develop%20a%20${props.plan}%20for%20me`


        window.location.href = href;


      }
      const wa = ()=> {
        let href = `https://wa.me/15716269126?text=Hi!%20i'd%like%you%to%20develop%20a%20${props.plan}%20for%20me`
        window.location.href = href;

      }
      const handleWpSoc = () => {
        let href = `https://wa.me/15716269126?text=Hi!%20i'd%like%you%to%20develop%20a%20${props.plan}%20for%20me`
 
 
         window.location.href = href;
 
 
       }


       const handleMemberReach = (website) => {
        let emailAddress = "innovadevx@gmail.com";
        let subject = website;
        let body = "Hi, I'm interested in your " + website + " plan with membership. Could you provide more information about the services you offer?";

        // Construct the mailto URL
        let mailtoUrl = "mailto:" + encodeURIComponent(emailAddress) +
                        "?subject=" + encodeURIComponent(subject) +
                        "&body=" + encodeURIComponent(body);

        // Set the location.href to the mailto URL
        window.location.href = mailtoUrl;
       }

       const handleReach = (website) => {
        let emailAddress = "innovadevx@gmail.com";
        let subject = website;
        let body = "Hi, I'm interested in your " + website + " plan. Could you provide more information about the services you offer?";

        // Construct the mailto URL
        let mailtoUrl = "mailto:" + encodeURIComponent(emailAddress) +
                        "?subject=" + encodeURIComponent(subject) +
                        "&body=" + encodeURIComponent(body);

        // Set the location.href to the mailto URL
        window.location.href = mailtoUrl;
       }

      useEffect(() => {
        AOS.init()
        handleStart()
        // handleStartM();
        // setTimeout(handleStartM, 100);
        ReactGA.send({ hitType: "pageview", page: "/" });
      }, [])
      
  return (
    <div>
      


      {props.eng && (
        <div className="precioBody" >
          <div className="desk">
          <HeaderF eng={true}></HeaderF>
          <div id="precioBody" className='precioLand'>
            <div data-aos="fade-left" data-aos-duration="1000" className="precioIzq">
                <h5 className="h5Precio"> <FontAwesomeIcon icon={faGlobe} className="precioIcon"></FontAwesomeIcon> {props.plan}</h5>
                <h2 className="h2Precio">{props.salesP}</h2>

                {!props.aboutUs && <div className="precioBotonera ">
                <button onClick={handleContacto} class="precioContact">
                          <span class="btn-text-one ">Want examples?</span>
                          <span class="btn-text-two">Click Here</span>
                        </button>
                    <div className="precioPrecioDiv">
                        <h4 className=" precioPrecio">{props.precio} usd</h4>
                        <span className="precioSep">|</span>
                        <h6 className="precioDesc">{props.formaPago}</h6>
                    </div>

                </div> }
                {props.aboutUs && <div className="precioBotonera ">
                <button onClick={handleContactoAB} class="precioContact">
                          <span class="btn-text-one ">MORE INFO?</span>
                          <span class="btn-text-two">Click Here</span>
                        </button>
                    <div className="precioPrecioDivAb">
                        <h4 className="precioPrecioAb">{props.precio} </h4>
                        <span className="precioSepAb">|</span>
                        <h6 className="precioDescAb">{props.formaPago}</h6>
                    </div>

                </div> }
            </div>


            <div data-aos="fade-right" data-aos-duration="1000" data-aos-delay="800" className={"precioDer " + props.clase}></div>
          </div>


        <img src={require('../img/separador.png')} alt="" className="separador animacion-horizontal"/>

        <div className="precioCaracs">
            <div className="precioCarac">
                <div className="precioCaracImgIzqDiv">
                    <img src={require('../img/' + props.img1)} alt="" className="animacion-horizontal2 precioCaracImgIzqImg"/>
                </div>
                <div className="precioCaracDer">
                    <div className="precioCaracTitleDiv">
                        <h3 className="precioCaracH3">{props.carac1Title}</h3>
                        {/* <img  src={require('../img/' + props.gif1)}  alt="" className="precioCaracImgIzqGif"/> */}
                    </div>
                    {/* <hr className="lineaCarac"/> */}

                    <div className="caracDesc">
                        <div className="caracDescRes">
                            
                            <h6 className="caracRes"><span className="iconCarac">{props.icon1}</span> {props.res1}</h6>
                        </div>
                        <p className="caracP">{props.p1}</p>
                    </div>
                </div>
            </div>

            <div className="precioCarac">
                <div className="precioCaracIzq">
                    <div className="precioCaracTitleDiv">
                        <h3 className="precioCaracH3">{props.carac2Title}</h3>
                        
                    </div>
                  

                    <div className="caracDesc">
                        <div className="caracDescRes">
                            
                            <h6 className="caracRes"><span className="iconCarac">{props.icon2}</span> {props.res2}</h6>
                        </div>
                        <p className="caracP">{props.p2}</p>
                    </div>
                </div>

                <div className="precioCaracImgDerDiv">
                    <img src={require('../img/' + props.img2)} alt="" className="precioCaracImgDerImg"/>
                </div>
            </div>

            {props.img3 && <div className="precioCarac">
                <div className="precioCaracImgIzqDiv">
                    <img src={require('../img/' + props.img3)} alt="" className="precioCaracImgIzqImg"/>
                </div>
                <div className="precioCaracDer">
                    <div className="precioCaracTitleDiv">
                        <h3 className="precioCaracH3">{props.carac3Title}</h3>
                     
                    </div>
                   

                    <div className="caracDesc">
                        <div className="caracDescRes">
                            
                            <h6 className="caracRes"><span className="iconCarac">{props.icon3}</span> {props.res3}</h6>
                        </div>
                        <p className="caracP">{props.p3}</p>
                    </div>
                </div>
            </div>}
            {props.img4 && <div className="precioCarac">
                <div className="precioCaracIzq">
                    <div className="precioCaracTitleDiv">
                        <h3 className="precioCaracH3">{props.carac4Title}</h3>
                        <img  src={require('../img/' + props.gif4)}  alt="" className="precioCaracImgIzqGif"/>
                    </div>
                    <hr className="lineaCarac"/>

                    <div className="caracDesc">
                        <div className="caracDescRes">
                            
                            <h6 className="caracRes"><span className="iconCarac">{props.icon4}</span> {props.res4}</h6>
                        </div>
                        <p className="caracP">{props.p4}</p>
                    </div>
                </div>

                <div className="precioCaracImgDerDiv">
                    <img src={require('../img/' + props.img4)} alt="" className="precioCaracImgDerImg"/>
                </div>
            </div>}
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
        </div>
        {props.aboutUs && (
    <div className="interestedAB">
       <div className="interestedTop">
       <h2>Interested in revolutionazing your business?</h2>
        <Link to='/services' className="abLink">Check out our services</Link>
       </div>
       <div className="interestedBottom">
        <div className="intBottomLeft">
        <h4 className="brand3">
            <FontAwesomeIcon icon={faMicrochip}></FontAwesomeIcon> innovatech.
          </h4>
          <h4>
            Dumfries, Virginia, USA.
          </h4>
          <h4>2024 InnovatechDev.</h4>
          

        </div>
        <div className="intBottomRight">
            <Link>
             <h4>  <FontAwesomeIcon className="iconAb" icon={faEnvelope}></FontAwesomeIcon> hello@innovatech.dev</h4>
            </Link>
       <h4>  <FontAwesomeIcon className="iconAb" icon={faInstagram}></FontAwesomeIcon> innovatech.dev</h4>
           <a href="">
           <h4><FontAwesomeIcon className="iconAb" icon={faPhone}></FontAwesomeIcon> 1 576 626 9126</h4>
           </a>
            
        </div>    


       </div>
    </div>
)}

        {!props.socio && <div data-aos="fade-bottom" data-aos-duration="800" className="precioPricing">

        <div className="txtPrecios">
            <div className="pricingProd">{props.plan}</div>
                <h2 className="h2PreciosPricing">Pay less, get the best.</h2>
               
                
              </div>
          
            <div className="preciosPricing">
            <div className="precioCard">
                <div className="precioCardFlex">
                <div className="izq">
                    <h4 className="membresiaPCard">Basic</h4>
                    <h2 className="precioCardPrecio">
                        {props.precio}
                    </h2>
                    <h5 className="precioCardForma">One payment</h5>
                </div>
                <div className="der">
                    <ul className="cardPrecioUl">
                        <li>
                         <span> <FontAwesomeIcon icon={faCircleCheck} className="cardPrecioIcon"></FontAwesomeIcon> </span>  {props.good1}
                        </li>
                        <li>
                         <span> <FontAwesomeIcon icon={faCircleCheck} className="cardPrecioIcon"></FontAwesomeIcon> </span>  {props.good2}
                        </li>
                        <li>
                         <span> <FontAwesomeIcon icon={faCircleCheck} className="cardPrecioIcon"></FontAwesomeIcon> </span>  {props.good3}
                        </li>

                        <li>
                         <span> <FontAwesomeIcon icon={faCircleXmark} className="cardPrecioIcon"></FontAwesomeIcon> </span>  {props.bad1}
                        </li>
                        <li>
                         <span> <FontAwesomeIcon icon={faCircleXmark} className="cardPrecioIcon"></FontAwesomeIcon> </span>  {props.bad2}
                        </li>
                        <li>
                         <span> <FontAwesomeIcon icon={faCircleXmark} className="cardPrecioIcon"></FontAwesomeIcon> </span>  {props.bad3}
                        </li>
                    </ul>
                </div>
                </div>

                <button onClick={()=> handleReach(planName)} className="buttonPricingCard">Reach out for free</button>
            </div>
          
            <div className="precioCard2">
                <div className="precioCardFlex">
                <div className="izq">
                    <h4 className="membresiaPCard2">Member</h4>
                    <h2 className="precioCardPrecio">
                        {props.precio2}
                    </h2>
                    <h5 className="precioCardForma">One payment or financing</h5>
                </div>
                <div className="der">
                    <ul className="cardPrecioUl2">
                        <li>
                        <span><FontAwesomeIcon icon={faCircleCheck} className="cardPrecioIcon"></FontAwesomeIcon></span> {props.good1}
                        </li>
                        <li>
                        <span><FontAwesomeIcon icon={faCircleCheck} className="cardPrecioIcon"></FontAwesomeIcon></span> {props.good2}
                        </li>
                        <li>
                        <span><FontAwesomeIcon icon={faCircleCheck} className="cardPrecioIcon"></FontAwesomeIcon></span> {props.good3}
                        </li>

                        <li>
                        <span>
                        <FontAwesomeIcon icon={faCircleCheck} className="cardPrecioIcon"></FontAwesomeIcon></span> {props.bad1}
                        </li>
                        <li>
                        <span>
                        <FontAwesomeIcon icon={faCircleCheck} className="cardPrecioIcon"></FontAwesomeIcon></span>{props.bad2}  
                        </li>
                        <li>
                        <span>
                        <FontAwesomeIcon icon={faCircleCheck} className="cardPrecioIcon"></FontAwesomeIcon></span>{props.bad3}  
                        </li>
                    </ul>
                </div>
                </div>

                <button onClick={() => handleMemberReach(planName)} className="buttonPricingCard2">Reach out for free</button>
            </div>
            </div>

            <Link className="sociedadLink" to='/member'>Find out more about the membership system.</Link>
        </div>}
       

{!props.aboutUs && (
    <div>
        <br />
<br />
    </div>
)}

       {!props.socio &&  <img src={require('../img/separador2.png')} alt="" className="separador animacion-horizontal"/>}





{!props.aboutUs && (
    <div>
        <span id='contacto'></span>
        <div className="landing2">
        <div data-aos="fade-up" className="txt2">
          <h4 className="brand2">
          <FontAwesomeIcon icon={faMicrochip}></FontAwesomeIcon> innova<span className="devx2">.devx</span>          </h4>
          <h3 className="landingDesc2">Innovate with us now.</h3>
          <br />
          <p className="pCaracF">Automate and simplify your business with a unique, effective, and competitive solution.</p>
          <br />
          <br />
          <br />
          <br />
          <Link onClick={handleReach} className="landingBtn2">Start Today</Link>
        </div>
        <div data-aos="fade-up" className="img2">
          <img src={require("../img/footer.png")} alt="" />
          <div className="redes">
            <h6>Reach us here:</h6>
            <div>
              <a className="aIno" href="mailto:innovadevx@gmail.com
"><FontAwesomeIcon icon={faEnvelope}></FontAwesomeIcon></a>
             
              <a className="aIno" href="https://www.instagram.com/innovadevx/"><FontAwesomeIcon icon={faInstagram}></FontAwesomeIcon></a>
              <a className="aIno" href="sms:+5716269126?body=Hi%im%20interested%20in%20your%20services."><FontAwesomeIcon icon={faComment}></FontAwesomeIcon></a>
            </div>
          </div>
        </div>
      </div>
    </div>
)}
          </div>

          <div className="mobile">
            <HeaderFM eng={true}></HeaderFM>


            <div id="precioBodyM" className="precioLandM">

            <div data-aos="fade-top" data-aos-duration="1000"  className={"precioDerM " + props.clase}></div>

            <div data-aos="fade-bottom" data-aos-delay="800" data-aos-duration="1200" className="precioIzqM">
                <h5 className="h5Precio"> <FontAwesomeIcon icon={faGlobe} className="precioIcon"></FontAwesomeIcon> {props.plan}</h5>
                <h2 className="h2Precio">{props.salesP}</h2>

                <div className="precioBotoneraM ">
                
                    <div className="precioPrecioDiv">
                       

                        {props.aboutUs && (
                           <h4 className="precioPrecio preciAb">{props.precio}</h4>
                        )}
                        {!props.aboutUs && (
                           <h4 className=" precioPrecio">{props.precio}</h4>
                        )}

                        <span className="precioSep">|</span>
                        {!props.aboutUs && (
                            <h6 className="precioDesc">{props.formaPago}</h6>
                        )}
                        {props.aboutUs && (
                            <h6 className="precioDescAB">{props.formaPago}</h6>
                        )}
                    </div>
                    {props.aboutUs && (
                        <Link to='/services' class="precioContactM">
                        Check it out
              </Link>
                    )}
                    {!props.aboutUs && (
                        <Link to='examples' class="precioContactM">
                        examples
              </Link>
                    )}

                </div>
            </div>
           
          </div>

          
       
        {/* <img src={require('../img/separador.png')} alt="" className="separador animacion-horizontal"/> */}



<div className="precioCaracsM">
            <div data-aos="fade-bottom" className="precioCaracM">
                <div className="precioCaracImgMdiv">
                    <img src={require('../img/' + props.img1)} alt="" className="precioCaracImgIzqImg"/>
                </div>
                <div className="precioCaracM">
                    <div className="precioCaracTitleDiv">
                        <h3 className="precioCaracH3">{props.carac1Title}</h3>
                       
                    </div>
                   

                    <div className="caracDesc">
                        
                        <p className="caracP">{props.p1}</p>
                    </div>
                </div>
            </div>

            <div data-aos="fade-bottom" className="precioCaracM">
                <div className="precioCaracImgMdiv">
                    <img src={require('../img/' + props.img2)} alt="" className="precioCaracImgIzqImg"/>
                </div>
                <div className="precioCaracM">
                    <div className="precioCaracTitleDiv">
                        <h3 className="precioCaracH3">{props.carac2Title}</h3>
                       
                    </div>
                   

                    <div className="caracDesc">
                        
                        <p className="caracP">{props.p2}</p>
                    </div>
                </div>
            </div>

            {props.img3 &&  <div data-aos="fade-bottom" className="precioCaracM">
                <div className="precioCaracImgMdiv">
                    <img src={require('../img/' + props.img3)} alt="" className="precioCaracImgIzqImg"/>
                </div>
                <div className="precioCaracM">
                    <div className="precioCaracTitleDiv">
                        <h3 className="precioCaracH3">{props.carac3Title}</h3>
                       
                    </div>

                    <div className="caracDesc">
                        
                        <p className="caracP">{props.p3}</p>
                    </div>
                </div>
            </div>}
            {props.img4 &&  <div data-aos="fade-bottom" className="precioCaracM">
                <div className="precioCaracImgMdiv">
                    <img src={require('../img/' + props.img4)} alt="" className="precioCaracImgIzqImg"/>
                </div>
                <div className="precioCaracM">
                    <div className="precioCaracTitleDiv">
                        <h3 className="precioCaracH3">{props.carac4Title}</h3>
                       
                    </div>
                    <hr className="lineaCarac"/>

                    <div className="caracDesc">
                        
                        <p className="caracP">{props.p4}</p>
                    </div>
                </div>
            </div>}
        </div>


        {!props.socio && <div data-aos="fade-bottom" data-aos-duration="800" className="precioPricing">
    <br />
        <div className="txtPrecios2">
            <div className="pricingProd">{props.plan}</div>
                <h2 className="h2PreciosPricing">Pay less, get the best.</h2>
                <h6 className="h6Precios">
              
                </h6>
                
              </div>
          
            <div className="preciosPricing">
            <div className="precioCard">
                <div className="precioCardFlex">
                <div className="izq">
                    <h4 className="membresiaPCard">Basic</h4>
                    <h2 className="precioCardPrecio">
                        {props.precio}
                    </h2>
                    <h5 className="precioCardForma">One payment</h5>
                </div>
                <div className="der">
                    <ul className="cardPrecioUl">
                        <li>
                        <span><FontAwesomeIcon icon={faCircleCheck} className="cardPrecioIcon"></FontAwesomeIcon></span> {props.good1}
                        </li>
                        <li>
                        <span><FontAwesomeIcon icon={faCircleCheck} className="cardPrecioIcon"></FontAwesomeIcon></span> {props.good2}
                        </li>
                        <li>
                        <span><FontAwesomeIcon icon={faCircleCheck} className="cardPrecioIcon"></FontAwesomeIcon></span>  {props.good3}
                        </li>

                        <li>
                        <span><FontAwesomeIcon icon={faCircleXmark} className="cardPrecioIcon"></FontAwesomeIcon></span> Free hosting and domain
                        </li>
                        <li>
                        <span><FontAwesomeIcon icon={faCircleXmark} className="cardPrecioIcon"></FontAwesomeIcon></span> Free updates and aditionals
                        </li>
                    </ul>
                </div>
                </div>

                <button onClick={handleWp} className="buttonPricingCard">Reach out for free</button>
            </div>
          
            <div className="precioCard2">
                <div className="precioCardFlex">
                <div className="izq">
                    <h4 className="membresiaPCard2">Member</h4>
                    <h2 className="precioCardPrecio">
                        {props.precio2}
                    </h2>
                    <h5 className="precioCardForma">One payment or with financing</h5>
                </div>
                <div className="der">
                    <ul className="cardPrecioUl2">
                        <li>
                        <span><FontAwesomeIcon icon={faCircleCheck} className="cardPrecioIcon"></FontAwesomeIcon></span> {props.good1}
                        </li>
                        <li>
                        <span><FontAwesomeIcon icon={faCircleCheck} className="cardPrecioIcon"></FontAwesomeIcon></span> {props.good2}
                        </li>
                        <li>
                        <span><FontAwesomeIcon icon={faCircleCheck} className="cardPrecioIcon"></FontAwesomeIcon></span> {props.good3}
                        </li>

                        <li>
                        <span>
                        <FontAwesomeIcon icon={faCircleCheck} className="cardPrecioIcon"></FontAwesomeIcon></span> Free updates and aditionals
                        </li>
                        <li>
                        <span>
                        <FontAwesomeIcon icon={faCircleCheck} className="cardPrecioIcon"></FontAwesomeIcon></span> Free domain and hosting
                        </li>
                    </ul>
                </div>
                </div>

                <button onClick={handleWpSoc} className="buttonPricingCard2">Reach out for free</button>
            </div>
            </div>
<br />
            <Link className="sociedadLink" to='/member'>Find out more about the membership system</Link>
            <br />
            <img src={require('../img/separador.png')} alt="" className="separador animacion-horizontal"/>

        </div>
        
        
        }
       
        <span id='contactoM'></span>
        {!props.aboutUs && <div className="landing2">
        <div className="img2" data-aos="fade-up" data-aos-duration="1000">
          <img src={require("../img/footer.png")} alt="" />
        </div>
        <div className="txt2" data-aos="fade-up" data-aos-duration="1000">
          <h3 className="landingDesc2">Innovate with us now.</h3>
          <br />
          <p className="pCarac">Simplify your business with a unique, effective, and competitive solution.</p>
          <br />
          <br />
          <button onClick={handleReach} className="landingBtn">Start Today</button>
        </div>
      </div>}
      {props.aboutUs && <div className="landing2">
        <div className="img2" data-aos="fade-up" data-aos-duration="1000">
          <img src={require("../img/footer.png")} alt="" />
        </div>
        <div className="txt2" data-aos="fade-up" data-aos-duration="1000">
          <h3 className="landingDesc2">Innovate with us now.</h3>
          <br />
          <p className="pCarac">Simplify your business with a unique, effective, and competitive solution.</p>
          <br />
          <br />
          <Link to='/services' className="landingBtn">CHECK OUT OUR SERVICES</Link>
        </div>
      </div>}
          </div>
        </div>
      )}
    </div>
  );
};

export default Precio;
