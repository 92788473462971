import "./css/precio.css";
import HeaderF from "../components/HeaderF";
import HeaderFM from "../components/HeaderFM";
import FooterM from "../components/FooterM";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleCheck, faCircleXmark, faEnvelope, faGlobe, faMicrochip, faComment } from "@fortawesome/free-solid-svg-icons";
import {
    faInstagram,
    faLinkedin,
    faTiktok,
    faWhatsapp,
    faGithub,
  } from "@fortawesome/free-brands-svg-icons";
import { Link } from "react-router-dom";
import AOS from 'aos';
import 'aos/dist/aos.css';
import Footer from "../components/Footer";
import { useEffect } from "react";
import ReactGA from 'react-ga4';

const Socio = (props) => {

    const handleStart = () => {
        const element = document.getElementById("precioBody");
      
        if (element) {
          element.scrollIntoView({ behavior: "smooth" });
        }
      
      };
      const handleStartM = () => {
        const element = document.getElementById("precioBodyM");
      
        if (element) {
          element.scrollIntoView({ behavior: "smooth" });
        }
      
      };
      const handleContacto = () => {
        const element = document.getElementById("contacto");
        if (element) {
          element.scrollIntoView({ behavior: "smooth" });
        }
     
      };
      const handleContactoM = () => {
        const element = document.getElementById("contactoM");
        if (element) {
          element.scrollIntoView({ behavior: "smooth" });
        }
      };

      const handleWp = () => {
       let href = `https://wa.me/15716269126?text=Hi!%20i'd%like%you%to%20develop%20a%20${props.plan}%20for%20me`


        window.location.href = href;


      }

      const wa = ()=> {
        let href = "https://wa.me/15716269126?text=¡Hola!%20me%20gustaria%20contratarte%20para%20desarrollar%20mi%20pagina%20web"
        window.location.href = href;

      }
      const handleWpSoc = () => {
        let href = `https://wa.me/3412756433?text=¡Hola!%20me%20gustaria%20contratarte %20para%20desarrollar%20mi%20${props.plan}%20con%20el%20plan%20socio`
 
 
         window.location.href = href;
 
 
       }

      useEffect(() => {
        AOS.init()
        handleStart()
        // handleStartM();
        setTimeout(handleStartM, 100);
        ReactGA.send({ hitType: "pageview", page: "/" });
      }, [])
      
  return (
    <div>
  

      {!props.esp && (
        <div className="precioBody" >
        <div className="desk">
        <HeaderF eng={true}></HeaderF>
        <div id="precioBody" className='precioLand'>
          <div data-aos="fade-left" data-aos-duration="1000" className="precioIzq">
              <h5 className="h5Precio"> <FontAwesomeIcon icon={faGlobe} className="precioIcon"></FontAwesomeIcon> {props.plan}</h5>
              <h2 className="h2Precio">{props.salesP}</h2>

              <div className="precioBotonera ">
              <button onClick={handleContacto} class="precioContact">
                        <span class="btn-text-one ">Interested?</span>
                        <span class="btn-text-two">Reach out for free</span>
                      </button>
                  <div className="precioPrecioDiv">
                      <h4 className=" precioPrecio">{props.precio} usd</h4>
                      <span className="precioSep">|</span>
                      <h6 className="precioDesc">Monthly Payments</h6>
                  </div>

              </div>
          </div>


          <div data-aos="fade-right" data-aos-duration="1000" data-aos-delay="800" className={"precioDer " + props.clase}></div>
        </div>


      <img src={require('../img/separador.png')} alt="" className="separador animacion-horizontal"/>

      <div className="precioCaracs">
          <div className="precioCarac">
              <div className="precioCaracImgIzqDiv">
                  <img src={require('../img/' + props.img1)} alt="" className="precioCaracImgIzqImg"/>
              </div>
              <div className="precioCaracDer">
                  <div className="precioCaracTitleDiv">
                      <h3 className="precioCaracH3">{props.carac1Title}</h3>
                      
                  </div>
                

                  <div className="caracDesc">
                      <div className="caracDescRes">
                          
                          <h6 className="caracRes"><span className="iconCarac">{props.icon1}</span> {props.res1}</h6>
                      </div>
                      <p className="caracP">{props.p1}</p>
                  </div>
              </div>
          </div>

          <div className="precioCarac">
              <div className="precioCaracIzq">
                  <div className="precioCaracTitleDiv">
                      <h3 className="precioCaracH3">{props.carac2Title}</h3>
                      
                  </div>
                

                  <div className="caracDesc">
                      <div className="caracDescRes">
                          
                          <h6 className="caracRes"><span className="iconCarac">{props.icon2}</span> {props.res2}</h6>
                      </div>
                      <p className="caracP">{props.p2}</p>
                  </div>
              </div>

              <div className="precioCaracImgDerDiv">
                  <img src={require('../img/' + props.img2)} alt="" className="precioCaracImgDerImg"/>
              </div>
          </div>

          {props.img3 && <div className="precioCarac">
              <div className="precioCaracImgIzqDiv">
                  <img src={require('../img/' + props.img3)} alt="" className="precioCaracImgIzqImg"/>
              </div>
              <div className="precioCaracDer">
                  <div className="precioCaracTitleDiv">
                      <h3 className="precioCaracH3">{props.carac3Title}</h3>
                      <img  src={require('../img/' + props.gif3)}  alt="" className="precioCaracImgIzqGif"/>
                  </div>
                  <hr className="lineaCarac"/>

                  <div className="caracDesc">
                      <div className="caracDescRes">
                          
                          <h6 className="caracRes"><span className="iconCarac">{props.icon3}</span> {props.res3}</h6>
                      </div>
                      <p className="caracP">{props.p3}</p>
                  </div>
              </div>
          </div>}
          {props.img4 && <div className="precioCarac">
              <div className="precioCaracIzq">
                  <div className="precioCaracTitleDiv">
                      <h3 className="precioCaracH3">{props.carac4Title}</h3>
                      <img  src={require('../img/' + props.gif4)}  alt="" className="precioCaracImgIzqGif"/>
                  </div>
                  <hr className="lineaCarac"/>

                  <div className="caracDesc">
                      <div className="caracDescRes">
                          
                          <h6 className="caracRes"><span className="iconCarac">{props.icon4}</span> {props.res4}</h6>
                      </div>
                      <p className="caracP">{props.p4}</p>
                  </div>
              </div>

              <div className="precioCaracImgDerDiv">
                  <img src={require('../img/' + props.img4)} alt="" className="precioCaracImgDerImg"/>
              </div>
          </div>}
          <br /><br />
          <br />
      </div>

      {!props.socio && <div data-aos="fade-bottom" data-aos-duration="800" className="precioPricing">
  <br />
      <div className="txtPrecios">
          <div className="pricingProd">{props.plan}</div>
              <h2 className="h2PreciosPricing">Pay less, get the best.</h2>
              <h6 className="h6Precios">
             
              </h6>
              
            </div>
        
          <div className="preciosPricing">
          <div className="precioCard">
              <div className="precioCardFlex">
              <div className="izq">
                  <h4 className="membresiaPCard">Basic</h4>
                  <h2 className="precioCardPrecio">
                      {props.precio}
                  </h2>
                  <h5 className="precioCardForma">Pay Once</h5>
              </div>
              <div className="der">
                  <ul className="cardPrecioUl">
                      <li>
                       <span> <FontAwesomeIcon icon={faCircleCheck} className="cardPrecioIcon"></FontAwesomeIcon> </span>  {props.good1}
                      </li>
                      <li>
                       <span> <FontAwesomeIcon icon={faCircleCheck} className="cardPrecioIcon"></FontAwesomeIcon> </span>  {props.good2}
                      </li>
                      <li>
                       <span> <FontAwesomeIcon icon={faCircleCheck} className="cardPrecioIcon"></FontAwesomeIcon> </span>  {props.good3}
                      </li>

                      <li>
                       <span> <FontAwesomeIcon icon={faCircleXmark} className="cardPrecioIcon"></FontAwesomeIcon> </span>  {props.bad1}
                      </li>
                      <li>
                       <span> <FontAwesomeIcon icon={faCircleXmark} className="cardPrecioIcon"></FontAwesomeIcon> </span>  {props.bad2}
                      </li>
                  </ul>
              </div>
              </div>

              <button onClick={handleWp} className="buttonPricingCard">Reach out for free</button>
          </div>
        
          <div className="precioCard2">
              <div className="precioCardFlex">
              <div className="izq">
                  <h4 className="membresiaPCard2">Member</h4>
                  <h2 className="precioCardPrecio">
                      {props.precio2}
                  </h2>
                  <h5 className="precioCardForma">Pay Once or with financing</h5>
              </div>
              <div className="der">
                  <ul className="cardPrecioUl2">
                      <li>
                      <span><FontAwesomeIcon icon={faCircleCheck} className="cardPrecioIcon"></FontAwesomeIcon></span> {props.good1}
                      </li>
                      <li>
                      <span><FontAwesomeIcon icon={faCircleCheck} className="cardPrecioIcon"></FontAwesomeIcon></span> {props.good2}
                      </li>
                      <li>
                      <span><FontAwesomeIcon icon={faCircleCheck} className="cardPrecioIcon"></FontAwesomeIcon></span> {props.good3}
                      </li>

                      <li>
                      <span>
                      <FontAwesomeIcon icon={faCircleCheck} className="cardPrecioIcon"></FontAwesomeIcon></span> {props.bad1}
                      </li>
                      <li>
                      <span>
                      <FontAwesomeIcon icon={faCircleCheck} className="cardPrecioIcon"></FontAwesomeIcon></span>{props.bad2}  
                      </li>
                  </ul>
              </div>
              </div>

              <button onClick={handleWpSoc} className="buttonPricingCard2">Reach out for free</button>
          </div>
          </div>

          <Link className="sociedadLink" to='/sociedad'>Get to know more about the membership system</Link>
      </div>}

  

<span id='contacto'></span>
<div className="landing2">
        <div data-aos="fade-up" className="txt2">
          <h4 className="brand2">
          <FontAwesomeIcon icon={faMicrochip}></FontAwesomeIcon> innova<span className="devx2">.devx</span>          </h4>
          <h3 className="landingDesc2">Innovate with us now.</h3>
          <br />
          <p className="pCaracF">Automate and simplify your business with a unique, effective, and competitive solution.</p>
          <br />
          <br />
          <br />
          <br />
          <Link to='/services' className="landingBtn2">Start Today</Link>
        </div>
        <div data-aos="fade-up" className="img2">
          <img src={require("../img/footer.png")} alt="" />
          <div className="redes">
            <h6>Reach us here:</h6>
            <div>
              <a className="aIno" href="mailto:innovadevx@gmail.com
"><FontAwesomeIcon icon={faEnvelope}></FontAwesomeIcon></a>
             
              <a className="aIno" href="https://www.instagram.com/innovadevx/"><FontAwesomeIcon icon={faInstagram}></FontAwesomeIcon></a>
              <a className="aIno" href="sms:+5716269126?body=Hi%im%20interested%20in%20your%20services."><FontAwesomeIcon icon={faComment}></FontAwesomeIcon></a>
            </div>
          </div>
        </div>
      </div>       </div>

        <div className="mobile">
          <HeaderFM eng={true}></HeaderFM>


          <div id="precioBodyM" className="precioLandM">

          <div className={"precioDerM " + props.clase}></div>

          <div className="precioIzqM">
              <h5 className="h5Precio"> <FontAwesomeIcon icon={faGlobe} className="precioIcon"></FontAwesomeIcon> {props.plan}</h5>
              <h2 className="h2Precio">{props.salesP}</h2>

              <div className="precioBotoneraM ">
              
                  <div className="precioPrecioDiv">
                      <h4 className=" precioPrecio">{props.precio}</h4>
                      <span className="precioSep">|</span>
                      <h6 className="precioDesc">Monthly Payments</h6>
                  </div>
                  <button onClick={wa} class="precioContactM">
                        <a style={{textDecoration: 'none', color: 'yellow'}} href="https://wa.me/15716269126?text=¡Hola!%20me%20gustaria%20contratarte%20para%20desarrollar%20mi%20pagina%20web">I want mine</a>
              </button>

              </div>
          </div>
         
        </div>

            
      
     
      {/* <img src={require('../img/separador.png')} alt="" className="separador animacion-horizontal"/> */}

<br /><br />

<div className="precioCaracsM">
          <div className="precioCaracM">
              <div className="precioCaracImgMdiv">
                  <img src={require('../img/' + props.img1)} alt="" className="precioCaracImgIzqImg"/>
              </div>
              <div className="precioCaracM">
                  <div className="precioCaracTitleDiv">
                      <h3 className="precioCaracH3">{props.carac1Title}</h3>
                     
                  </div>
                  {/* <hr className="lineaCarac"/> */}

                  <div className="caracDesc">
                      
                      <p className="caracP">{props.p1}</p>
                  </div>
              </div>
          </div>

          <div className="precioCaracM">
              <div className="precioCaracImgMdiv">
                  <img src={require('../img/' + props.img2)} alt="" className="precioCaracImgIzqImg"/>
              </div>
              <div className="precioCaracM">
                  <div className="precioCaracTitleDiv">
                      <h3 className="precioCaracH3">{props.carac2Title}</h3>
                     
                  </div>
                  {/* <hr className="lineaCarac"/> */}

                  <div className="caracDesc">
                      
                      <p className="caracP">{props.p2}</p>
                  </div>
              </div>
          </div>

          {props.img3 &&  <div className="precioCaracM">
              <div className="precioCaracImgMdiv">
                  <img src={require('../img/' + props.img3)} alt="" className="precioCaracImgIzqImg"/>
              </div>
              <div className="precioCaracM">
                  <div className="precioCaracTitleDiv">
                      <h3 className="precioCaracH3">{props.carac3Title}</h3>
                     
                  </div>
                  {/* <hr className="lineaCarac"/> */}

                  <div className="caracDesc">
                      
                      <p className="caracP">{props.p3}</p>
                  </div>
              </div>
          </div>}
          {props.img4 &&  <div className="precioCaracM">
              <div className="precioCaracImgMdiv">
                  <img src={require('../img/' + props.img4)} alt="" className="precioCaracImgIzqImg"/>
              </div>
              <div className="precioCaracM">
                  <div className="precioCaracTitleDiv">
                      <h3 className="precioCaracH3">{props.carac4Title}</h3>
                     
                  </div>
                  <hr className="lineaCarac"/>

                  <div className="caracDesc">
                      
                      <p className="caracP">{props.p4}</p>
                  </div>
              </div>
          </div>}
      </div>


      {!props.socio && <div data-aos="fade-bottom" data-aos-duration="800" className="precioPricing">
  <br />
      <div className="txtPrecios">
          <div className="pricingProd">{props.plan}</div>
              <h2 className="h2PreciosPricing">Pay less, get the best</h2>
              
              
            </div>
        
          <div className="preciosPricing">
          <div className="precioCard">
              <div className="precioCardFlex">
              <div className="izq">
                  <h4 className="membresiaPCard">Basic</h4>
                  <h2 className="precioCardPrecio">
                      {props.precio}
                  </h2>
                  <h5 className="precioCardForma">Pay Once</h5>
              </div>
              <div className="der">
                  <ul className="cardPrecioUl">
                      <li>
                      <span><FontAwesomeIcon icon={faCircleCheck} className="cardPrecioIcon"></FontAwesomeIcon></span> {props.good1}
                      </li>
                      <li>
                      <span><FontAwesomeIcon icon={faCircleCheck} className="cardPrecioIcon"></FontAwesomeIcon></span> {props.good2}
                      </li>
                      <li>
                      <span><FontAwesomeIcon icon={faCircleCheck} className="cardPrecioIcon"></FontAwesomeIcon></span>  {props.good3}
                      </li>

                      <li>
                      <span><FontAwesomeIcon icon={faCircleXmark} className="cardPrecioIcon"></FontAwesomeIcon></span> Free hosting and domain.
                      </li>
                      <li>
                      <span><FontAwesomeIcon icon={faCircleXmark} className="cardPrecioIcon"></FontAwesomeIcon></span> Free updates and aditionals.
                      </li>
                  </ul>
              </div>
              </div>

              <button onClick={handleWp} className="buttonPricingCard">Reach out for free</button>
          </div>
        
          <div className="precioCard2">
              <div className="precioCardFlex">
              <div className="izq">
                  <h4 className="membresiaPCard2">Member</h4>
                  <h2 className="precioCardPrecio">
                      {props.precio2}
                  </h2>
                  <h5 className="precioCardForma">Pay once or with financing</h5>
              </div>
              <div className="der">
                  <ul className="cardPrecioUl2">
                      <li>
                      <span><FontAwesomeIcon icon={faCircleCheck} className="cardPrecioIcon"></FontAwesomeIcon></span> {props.good1}
                      </li>
                      <li>
                      <span><FontAwesomeIcon icon={faCircleCheck} className="cardPrecioIcon"></FontAwesomeIcon></span> {props.good2}
                      </li>
                      <li>
                      <span><FontAwesomeIcon icon={faCircleCheck} className="cardPrecioIcon"></FontAwesomeIcon></span> {props.good3}
                      </li>

                      <li>
                      <span>
                      <FontAwesomeIcon icon={faCircleCheck} className="cardPrecioIcon"></FontAwesomeIcon></span> Free updates and aditionals
                      </li>
                      <li>
                      <span>
                      <FontAwesomeIcon icon={faCircleCheck} className="cardPrecioIcon"></FontAwesomeIcon></span> Free hosting and domain
                      </li>
                  </ul>
              </div>
              </div>

              <button onClick={handleWpSoc} className="buttonPricingCard2">Reach out for free</button>
          </div>
          </div>

          <Link className="sociedadLink" to='/sociedad'>Find out more about the memberships system</Link>
      </div>}
     
      <span id='contactoM'></span>
      <div className="landing2">
        <div className="img2" data-aos="fade-up" data-aos-duration="1000">
          <img src={require("../img/footer.png")} alt="" />
        </div>
        <div className="txt2" data-aos="fade-up" data-aos-duration="1000">
          <h3 className="landingDesc2">Innovate with us now.</h3>
          <br />
          <p className="pCarac">Automate and simplify your business with a unique, effective, and competitive solution.

</p>
          <br />
          <br />
          <button className="landingBtn">Start Today</button>
        </div>
      </div>
        </div>
      </div>
      )}
    </div>
  );
};

export default Socio;
