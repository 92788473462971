import { faMicrochip } from "@fortawesome/free-solid-svg-icons";
import "./css/headerF.css";
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
const HeaderF = (props) => {
  return (
    <div>
     

      {props.eng && (
        <div className="esp">
          <div className="headerL">
          
            <div className="logoHeader">
              <Link to='/eng' className="" style={{textDecoration: 'none'}}>
                
                <h3 className="jmbH"><FontAwesomeIcon icon={faMicrochip}></FontAwesomeIcon> Innovadevx</h3>
              </Link>
            </div>
           

            <div className="linksHeader">
                <Link to='/eng' className="linkHeader">
                    Home
                </Link>
                <Link to='/services' className="linkHeader">
                    Services
                </Link>
                <Link to='/about' className="linkHeader">
                    About Us
                </Link>
               
               
                <a href='mailto:innovadevx@gmail.com' className="linkHeader">
                    Email Us
                </a>
            </div>

            <div className="afiliacionDiv">
                <Link to='/member' className={props.h2 ? "afiliacionHeader2" : "afiliacionHeader"}>
                    Membership
                </Link>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default HeaderF;
