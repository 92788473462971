import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import Precio from "./pages/Precio";
import Socio from "./pages/Socio";
import Home from "./pages/Home";
import Player from "./pages/Player";
import "./App.css";
import Servicio from "./pages/Servicio";

import Precios from "./pages/Precios";
import Proyectos from "./pages/Proyectos";
import NotFound from "./pages/NotFound";

import ReactGA from "react-ga4";

function App() {
  ReactGA.initialize("G-LL2DQWBKCK");

  const landingP = "$50";
  const landingP2 = "$30";
  const landingSP = "$25";
  const basicaP = "$95";
  const basicaP2 = "$85";
  const basicaSP = "$50";
  const cartaP = "$35";
  const cartaP2 = "$10";
  const cartaSP = "$50";
  const menuP = "$85";
  const menuP2 = "$70";
  const menuSP = "$50";
  const eccomerceP = "$590";
  const eccomerceP2 = "$550";
  const eccomerceSP = "$90";

  const personalidadP = "$250";
  const personalidadP2 = "$220";
  const personalidadSP = "$25";
  const freelanceP = "$350";
  const freelanceP2 = "$315";
  const freelanceSP = "$30";
  const marcaP = "$500";
  const marcaP2 = "$450";
  const marcaSP = "$50";

  return (
    <Router>
      <Routes>
        <Route path="*" element={<NotFound />} />
        <Route exact path="/" element={<Home eng={true} init={true} />}></Route>
        <Route path="/home" element={<Home eng={true} init={true} />}></Route>

        <Route path="/eng" element={<Home eng={true} init={true} />}></Route>

        <Route path="/services" element={<Precios eng={true} />}></Route>

        {/* ABOUT ENG */}
        <Route
          path="/about"
          element={
            <Precio
              clase="aboutPrecio"
              socio={true}
              formaPago="if you didnt like the design."
              aboutUs={true}
              eng={true}
              plan="About Us"
              salesP="Committed with your satisfaction."
              precio="Full Refund"
              precio2={landingP2}
              img1="moon.png"
              carac1Title="About Innovadevx and our main goal."
              gif1="rocket.gif"
              p1={
                <p>
                  We are <b>InnovaDevx</b>, a digital presence agency dedicated to providing you with the finest and most personalized solutions at competitive prices. Our main goal is to empower individuals and businesses to portray their true spirit online at an affordable cost.
                </p>
              }
              icon1="#1"
              res1="Dedicated Team"
              img2="Finances.png"
              carac2Title="The excellence you deserve at the price you can afford"
              gif2="medal.gif"
              p2="We offer cost-effective solutions, empowering you to ride the waves of the digital revolution affordably and effortlessly."
              icon2="A+"
              res2="Quality > Price"
              img3="BusinessPlan.png"
              carac3Title="We engineer tailored digital solutions; if it's not unique, it's not ours."
              gif3="sales.gif"
              p3="Our primary objective is to deliver solutions to our clients that truly reflect the essence of their businesses, rather than simply offering a generic Wordpress template or an AI-generated social media strategy."
              icon3="#1"
              res3="Always Unique"
              good1="Portfolio"
              good2="Final Source Code"
              good3="Personalized Service"
              bad1="Free Domain"
              bad2="Free Hosting"
              bad3="Free Updates"
            ></Precio>
          }
        ></Route>

        {/* MEMBER */}
        <Route
          path="/member"
          element={
            <Socio
              eng={true}
              socio={true}
              clase="socio"
              plan="Web Membership"
              salesP="Digital presence at the best price."
              precio="$25"
              formaPago="Monthly payment"
              img1="BusinessPlan.png"
              carac1Title="Enjoy your website at a reduced price"
              gif1="offer.gif"
              p1="By paying the monthly web membership, you have access to special prices on all plans, various free maintenance and update services, and special features."
              icon1="$$"
              res1="Save big"
              img2="Finances.png"
              carac2Title="Save a lot of money with free aditionals"
              gif2="coin.gif"
              p2="With the web membership, you won't have to pay for updating the content of your website, nor for the domain or hosting. Additionally, you can request maintenance tasks at no additional cost."
              icon2="$0"
              res2="in aditionals"
            />
          }
        ></Route>

        {/* portfolio ENG */}
        <Route
          path="/services/portfolio"
          element={
            <Precio
              clase="landingPrecio"
              formaPago="Starter Price Offered."
              eng={true}
              plan="Portfolio Website"
              salesP="Boost your success with a website."
              precio={landingP}
              precio2={landingP2}
              img1="moon.png"
              carac1Title="Take your personal brand to the moon."
              gif1="rocket.gif"
              p1={
                <p>
                  Your portfolio website is your <b>digital showcase</b>,
                  highlighting your <b>skills and achievements</b> to potential
                  clients and employers. It's a powerful tool to generate
                  traffic, impress with personalized presentations, and{" "}
                  <b>build trust</b> through testimonials.
                </p>
              }
              icon1="2x"
              res1="Double Reach"
              img2="Finances.png"
              carac2Title="The excellence you deserve at the price you can afford"
              gif2="medal.gif"
              p2={
                <p>
                  We provide a budget-friendly solution with our portfolio
                  service to help you showcase your work effectively, attract
                  more clients, and elevate your brand.
                </p>
              }
              icon2="A+"
              res2="Quality > Price"
              img3="BusinessPlan.png"
              carac3Title="Attract more customers with an irresistible website"
              gif3="sales.gif"
              p3={
                <p>
                  Maximize conversions and <b>attract more customers</b> with a
                  compelling portfolio website. Craft an irresistible online
                  presence that showcases your expertise, captivates your
                  audience, and <b>onverts visitors into loyal customers</b>.
                </p>
              }
              icon3="2x"
              res3="Double customers"
              good1="Portfolio"
              good2="Final Source Code"
              good3="Personalized Service"
              bad1="Free Domain"
              bad2="Free Hosting"
              bad3="Free Updates"
            ></Precio>
          }
        ></Route>

        {/* business ENG */}
        <Route
          path="/services/business"
          element={
            <Precio
              clase="basicaPrecio"
              formaPago="Starter Price Offered"
              eng={true}
              plan="Business Website"
              salesP="A unique website for your brand."
              precio={basicaP}
              precio2={basicaP2}
              img1="moon.png"
              carac1Title="The best way to make your brand accessible to everyone"
              gif1="wrold.gif"
              p1={
                <p>
                  <b>Do you want your brand to grow and expand?</b> Then you
                  need a website. With a website, you can showcase your brand,
                  products or services, testimonials, contacts, and everything
                  you want in a single website!
                </p>
              }
              icon1="2x"
              res1="Double Reach"
              img2="Finances.png"
              carac2Title="Excellence you deserve at a price you can afford"
              gif2="medal.gif"
              p2={
                <p>
                  I know you're looking for the best for your business, but I
                  also know you have a limited budget. That's why I offer you a
                  quality solution, tailored to your needs and your wallet. With
                  my segmented website plan, you can attract more customers,
                  generate more sales, and grow your brand like never before.
                </p>
              }
              icon2="A+"
              res2="Quality > Price"
              img3="basket.png"
              carac3Title="Make your brand shine with a unique and striking website"
              gif3="trophy.gif"
              p3="Do you want your brand to stand out from the competition and attract more customers? We offer you a unique and striking website, tailored to your needs and incorporating the latest web design trends."
              icon3="#1"
              res3="Best Website"
              good1="Business Website"
              good2="Final Source Code"
              good3="Personalized Service"
              bad1="Free Domain"
              bad2="Free Hosting"
              bad3="Free Updates"
            ></Precio>
          }
        ></Route>

        {/* companies ENG */}
        <Route
          path="/services/companies"
          element={
            <Precio
              clase="basicaPrecio"
              formaPago="Starter Price Offered."
              eng={true}
              plan="Corporate Website"
              salesP="A unique website for your brand."
              precio={basicaP}
              precio2={basicaP2}
              img1="moon.png"
              carac1Title="The best way to make your brand accessible to everyone."
              gif1="wrold.gif"
              p1={
                <p>
                  <b>Do you want your brand to grow and expand?</b> Then you
                  need a website. With a website, you can showcase your brand,
                  products or services, testimonials, contacts, and everything
                  you want in a single website!
                </p>
              }
              icon1="2x"
              res1="Double Reach"
              img2="Finances.png"
              carac2Title="Excellence you deserve at a price you can afford."
              gif2="medal.gif"
              p2={
                <p>
                  We know you're looking for the best for your business, but we
                  also know you have a limited budget. That's why we offer you a
                  quality solution, tailored to your needs and your wallet. With
                  our companies oriented plan, you can attract more customers,
                  generate more sales, and grow your brand like never before.
                </p>
              }
              icon2="A+"
              res2="Quality > Price"
              img3="basket.png"
              carac3Title="Make your brand shine with a unique and striking website."
              gif3="trophy.gif"
              p3="Do you want your brand to stand out from the competition and attract more customers? We offer you a unique and striking website, tailored to your needs and incorporating the latest web design trends."
              icon3="#1"
              res3="Best Website"
              good1="Corporate Website"
              good2="Final Source Code"
              good3="Personalized Service"
              bad1="Free Domain"
              bad3="Free Hosting"
              bad2="Free Updates"
            ></Precio>
          }
        ></Route>

        {/* EVENTS ENG */}
        <Route
          path="/services/events"
          element={
            <Precio
              clase="eventPrecio"
              formaPago="One-time Payment"
              eng={true}
              plan="Event Website"
              salesP="Maximize attendance to your event."
              precio={cartaP}
              precio2={cartaP2}
              img1="dishes.png"
              carac1Title="Get people to register to your event 24/7 from anywhere."
              gif1="rocket.gif"
              p1={
                <p>
                  Experience <b>event registration </b>without hassles{" "}
                  <b>anytime</b>, <b>anywhere</b> with our customized websites.
                  Attract your audience <b>24/7</b> and <b>boost attendance</b>{" "}
                  like never before! Elevate your event promotion strategy now!
                </p>
              }
              icon1="4x"
              res1="Faster Registration"
              img2="Finances.png"
              carac2Title="The excellence you deserve at the price you can afford"
              gif2="medal.gif"
              p2="
      I know you're looking for the best for your business, but also that you have a limited budget. That's why I offer you a quality solution, tailored to your needs and your wallet. With my landing page, you'll be able to attract more customers, generate more sales, and grow your brand like never before."
              icon2="A+"
              res2="Quality > Price"
              img3="mesero.png"
              carac3Title="Make it easier for everyone with online registration."
              gif3="qr.gif"
              p3={
                <p>
                  <b>Simplify the process</b> for everyone with convenient
                  online registration. Empower attendees to{" "}
                  <b>sign up hassle-free</b>, while you effortlessly manage
                  registrations and <b>access</b> valuable attendee{" "}
                  <b>insights</b>.
                </p>
              }
              icon3="2x"
              res3="More Efficient"
              good1="Personalized Event Website"
              good2="Final Source Code"
              good3="Personalized Service"
              bad2="Free domain"
              bad1="Free Updates."
              bad3="Free Hosting."
            ></Precio>
          }
        ></Route>

        {/* SUBSCRIPTION ENG */}
        <Route
          path="/services/subscriptions"
          element={
            <Precio
              clase="subPrecio"
              formaPago="One-time Payment"
              eng={true}
              plan="Suscription Website"
              salesP="Let users suscribe to anything, fast."
              precio={cartaP}
              precio2={cartaP2}
              img1="dishes.png"
              carac1Title="Get people to suscribe to your service 24/7 from anywhere."
              gif1="rocket.gif"
              p1={
                <p>
                  Users can <b>suscribe to your services </b>without hassles{" "}
                  <b>anytime</b>, <b>anywhere</b> with our customized websites.
                  Attract your audience <b>24/7</b> and{" "}
                  <b>boost subscriptions</b> like never before!
                </p>
              }
              icon1="4x"
              res1="More Subscriptions"
              img2="Finances.png"
              carac2Title="The excellence you deserve at the price you can afford"
              gif2="medal.gif"
              p2="
      I know you're looking for the best for your business, but also that you have a limited budget. That's why I offer you a quality solution, tailored to your needs and your wallet. With my landing page, you'll be able to attract more customers, generate more sales, and grow your brand like never before."
              icon2="A+"
              res2="Quality > Price"
              img3="mesero.png"
              carac3Title="Make it easier for everyone with online subscription."
              gif3="qr.gif"
              p3={
                <p>
                  <b>Simplify the process</b> for everyone with convenient
                  online registration. Effortlessly manage registrations and{" "}
                  <b>access</b> valuable subscribers <b>insights</b>.
                </p>
              }
              icon3="2x"
              res3="More Efficient"
              good1="Subscription Website"
              good2="Final Source Code"
              good3="Personalized Service"
              bad2="Free domain"
              bad1="Free Updates."
              bad3="Free Hosting."
            ></Precio>
          }
        ></Route>

        {/* ONLINE STORE ENG */}
        <Route
          path="/services/store"
          element={
            <Precio
              clase="ecomercePrecio"
              formaPago="Starter Price Offered"
              eng={true}
              plan="Online store"
              salesP="Sell online with a unique store."
              precio={eccomerceP}
              precio2={eccomerceP2}
              img1="ventas.png"
              carac1Title="Sell without limits with a unique and personalized ecommerce"
              gif1="tienda.gif"
              p1={
                <p>
                  Do you want to have an online store that adapts to your needs
                  and those of your customers? Then, you need a{" "}
                  <b>unique ecommerce</b>, designed and developed to your
                  measure and with the best tools and resources.
                  <b> Sell everything 100% online</b> with a unique ecommerce.
                </p>
              }
              icon1="+$"
              res1="Sell online"
              img2="Finances.png"
              carac2Title="The excellence you deserve at the price you can afford"
              gif2="medal.gif"
              p2="I know you're looking for the best for your business, but also that you have a limited budget. That's why I offer you a quality solution, tailored to your needs and your wallet. With my online store plan, you'll be able to attract more customers, generate more sales, and grow your brand like never before."
              icon2="A+"
              res2="Quality > Price"
              img3="pedido.png"
              carac3Title="Streamline the sales process like never before"
              gif3="stats.gif"
              p3={
                <p>
                  An ecommerce allows you to showcase your products or services,
                  receive orders and process payments. We offer you a
                  personalized, professional, and quality service that will
                  provide you with an ecommerce that streamlines your sales
                  process like never before.
                </p>
              }
              icon3="2x"
              res3="Faster Sales"
              good1="Custom Online Store"
              good2="Final Source Code."
              good3="Personalized Service"
              bad1="Free Domain."
              bad2="Free Updates."
              bad3="Free Hosting."
            ></Precio>
          }
        ></Route>


        {/* Service ENG */}
<Route
  path="/services/service"
  element={
    <Precio
      clase="pedidosPrecio"
      formaPago="Starter Price Offered"
      eng={true}
      plan="Service Based Website"
      salesP="Provide your services to anyone."
      precio={menuP}
      precio2={menuP2}
      img1="espera.png"
      carac1Title="Never pay extra for promoting your services online again."
      gif1="offer.gif"
      p1="If you want to promote your services online without paying extra commissions, what you need is your own web. With a website, you can have your services listed on your platform and start promoting your services directly to your customers."
      icon1="$0"
      res1="Extra Commissions"
      img2="Finances.png"
      carac2Title="The excellence you deserve at the price you can afford"
      gif2="medal.gif"
      p2="
      I know you're looking for the best for your business, but also that you have a limited budget. That's why we offer you a quality solution, tailored to your needs and your wallet. With our websites, you'll be able to attract more customers, generate more sales, and grow your brand like never before."
      icon2="A+"
      res2="Quality > Price"
      img3="casa.png"
      carac3Title="Double your clients and your reach with an innovative website."
      gif3="delivery.gif"
      p3="Transform your marketing with a powerful website. Stand out, attract more opportunities, and effortlessly showcase your expertise. Experience the difference a professional website can make in expanding your reach and boosting your success."
      icon3="2x"
      res3="more clients"
      good1="Service Based Website"
      good2="Final Source Code"
      good3="Personalized Service"
      bad1="Free Domain"
      bad2="Free Hosting."
      bad3="Free Updates."

    ></Precio>
  }

></Route>

        {/*------- EXAMPLES ----------- */}
        
        {/* portfolio example */}
        <Route
          path="/services/portfolio/examples"
          element={
            <Player
              eng={true}
              link={"/services/portfolio"}
              name="Portfolio Website"
              video="videoPortfolio.mp4"
            />
          }
        ></Route>

        {/* subs example */}
        <Route
          path="/services/subscriptions/examples"
          element={
            <Player
              eng={true}
              link={"/services/subscriptions"}
              name="Subscription Website"
              video="videoMember.mp4"
            />
          }
        ></Route>

        {/* companies example */}
        <Route
          path="/services/companies/examples"
          element={
            <Player
              eng={true}
              link={"/services/companies"}
              name="Companies Website"
              video="vantaFast.mp4"
            />
          }
        ></Route>

        {/* store example */}
        <Route
          path="/services/store/examples"
          element={
            <Player
              eng={true}
              link={"/services/store"}
              name="Online Store Website"
              video="storeVideo.mp4"
            />
          }
        ></Route>

        {/* events example */}
        <Route
          path="/services/events/examples"
          element={
            <Player
              eng={true}
              link={"/services/events"}
              name="Event Website"
              video="videoEvent.mp4"
            />
          }
        ></Route>

        {/* business example */}
        <Route
          path="/services/business/examples"
          element={
            <Player
              eng={true}
              link={"/services/business"}
              name="Business Website"
              video="videoSable.mp4"
            />
          }
        ></Route>

        {/* service example */}
        <Route
          path="/services/service/examples"
          element={
            <Player
              eng={true}
              link={"/services/service"}
              name="Services Website"
              video="delprato.mp4"
            />
          }
        ></Route>



      </Routes>

    </Router>
  );
}

export default App;
