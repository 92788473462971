import "./css/headerF.css";
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faCross, faEnvelope, faGlobe, faMicrochip, faX } from "@fortawesome/free-solid-svg-icons";
import { useState } from "react";

const HeaderFM = (props) => {
    

    const [header, setheader] = useState(false)

  return (
    <div>
      {props.eng && (
         <div className="esp">
        
         <div className=" headerLM">
              <div className="headLm">
              <div className="divLogo">
               <Link to='/home' style={{textDecoration: 'none'}}>
               <h3 className="logoHeader">
                 <FontAwesomeIcon className="iconoHeader" icon={faMicrochip}></FontAwesomeIcon>
                 
               </h3></Link>
               </div>
   
               {!header && <FontAwesomeIcon className="flip-in-hor-top barsFM" icon={faBars} onClick={()=> setheader(!header)}></FontAwesomeIcon>}
               {header && <FontAwesomeIcon className="flip-in-hor-bottom barsFM" icon={faX} onClick={()=> setheader(!header)}></FontAwesomeIcon>}
              </div>
   
              {header && <div className="linksHeaderFM">
                   <Link to='/eng' className="slide-in-left linkHeaderFM">
                       Home
                   </Link>
                   <Link to='/about' className="slide-in-left linkHeaderFM">
                       About Us
                   </Link>
                   <Link to='/services' className="slide-in-left linkHeaderFM">
                       Services
                   </Link>
                  
                  
                 
                   <a href="mailto:innovadevx@gmail.com" className="slide-in-left linkHeaderFM">Email Us</a>

                   <Link to='/member' className="slide-in-left linkHeaderFM">
                       Membership
                   </Link>
                
               </div>}
             </div>
             
           </div>
      )}
        {props.black && (
         <div className="esp">
        
         <div className=" headerLM">
              <div className="headLm">
              <div className="divLogo">
               <Link to='/home' style={{textDecoration: 'none'}}>
               <h3 className="logoHeader">
                 <FontAwesomeIcon className="iconoHeader" style={{color: 'black'}} icon={faMicrochip}></FontAwesomeIcon>
                 
               </h3></Link>
               </div>
   
               {!header && <FontAwesomeIcon  style={{color: 'black'}} className="flip-in-hor-top barsFM" icon={faBars} onClick={()=> setheader(!header)}></FontAwesomeIcon>}
               {header && <FontAwesomeIcon className="flip-in-hor-bottom barsFM" icon={faX} onClick={()=> setheader(!header)}></FontAwesomeIcon>}
              </div>
   
              {header && <div className="linksHeaderFM">
                   <Link to='/eng' className="slide-in-left linkHeaderFM">
                       Home
                   </Link>
                   <Link to='/about' className="slide-in-left linkHeaderFM">
                       About Us
                   </Link>
                   <Link to='/services' className="slide-in-left linkHeaderFM">
                       Services
                   </Link>
                  
                  
                 
                   <a href="mailto:innovadevx@gmail.com" className="slide-in-left linkHeaderFM">Email Us</a>

                   <Link to='/member' className="slide-in-left linkHeaderFM">
                       Membership
                   </Link>
                
               </div>}
             </div>
             
           </div>
      )}
    </div>
  );
};

export default HeaderFM;