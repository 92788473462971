import { Link } from "react-router-dom";
import HeaderF from "../components/HeaderF";
import HeaderFM from "../components/HeaderFM";

export default function NotFound() {
    return (
        <div className="a4">
            <HeaderF eng={true}></HeaderF>
           
         
          <div className="div404">
          <img src={require('../img/404.png')} className="b404" alt="" />
            <h3 className="h3404">Oops! This page doesnt exist.</h3>
            <p className="p404">Here are some useful links:</p>
            
            <Link className="p404" to='/home'>Home</Link>
            <Link className="p404" to='/services'>Services</Link>
          </div>
            
        </div>
    )
}