import HeaderF from "../components/HeaderF";
import HeaderFM from "../components/HeaderFM";
import "./css/player.css";
import { Link } from "react-router-dom";

const Player = (props) => {
  return (
    <div className="">
      <div className="desk playerBody">
        <HeaderF eng={true}></HeaderF>

<div className="divIframe">
<br />
<br />
<br />
<br />
<br />
    <h3 className="iframeH3">
        {props.name} example
    </h3>
    <Link className="bounce-top linkPlayer" to={props.link}>Click here if you want to go back to the previous page</Link>
    <br />
    <br />
    
<iframe className="iframePlayer" src={require('../img/' + props.video)} frameborder="0"></iframe>
<br />
<br />
</div>
      </div>

      <div className="mobile">
        {/* <HeaderFM eng={true}></HeaderFM> */}
        <div className="playerM">
    
       <video className="videoM" controls>
        <source src={require('../img/' + props.video)} type="video/mp4"/>
        Your browser does not support the video tag.
    </video>
        </div>
      </div>
    </div>
  );
};

export default Player;
